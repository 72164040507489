<template>
  <div class="main-container">
    <v-row class="alert-container">
      <v-alert v-if="showMessage" class="alert" :type="messageType" dark>
        {{ message }}
      </v-alert>
    </v-row>
    <v-overlay
      v-if="serverIsDown"
      class="server-is-down"
      color="black"
      opacity="0.4"
      >Server is Down</v-overlay
    >
    <v-row class="qa-container">
      <v-col cols="12" lg="6" xl="6" md="12" sm="12">
        <v-container class="question-container">
          <v-card class="card-question" elevation="5">
            <v-card-title>Question</v-card-title>
            <v-row class="align-center" justify="center">
              <!-- <v-col cols="2">
                <v-checkbox
                  label="Token Prediction"
                  v-model="tokenPrediction"
                  density="compact"
                />
              </v-col>
              <v-col cols="2">
                <v-select
                  v-if="tokenPrediction"
                  :items="tokenPredictors"
                  label="Voice"
                  item-text="name"
                  item-value="name"
                  v-model="tokenPredictor"
                  density="compact"
                />
              </v-col> -->
              <v-col cols="4" offset="4">
                <v-select
                  :items="voiceList"
                  label="Voice"
                  item-text="name"
                  v-model="questionVoiceName"
                  density="compact"
                />
              </v-col>
              <v-col cols="auto">
                <transition
                  name="fade"
                  v-if="isVoicesLoading || waitingForAnswer"
                >
                  <scale-loader
                    class="d-flex justify-center loading-voices"
                    color="#dd3333"
                    size="15px"
                    margin="2px"
                  />
                </transition>
                <v-btn
                  :disabled="!this.question"
                  text
                  icon
                  @click="toggleSpeakQuestion"
                  :class="{ 'pulse': isTalkingQuestion }"
                >
                  <v-img
                    :src="
                      speakOutQuestion ? iconSpeakQuestion : iconNoSpeakQuestion
                    "
                    width="50px"
                  ></v-img>
                </v-btn>
              </v-col>
            </v-row>
            <v-card-text>
              <v-textarea
                v-model="question"
                :disabled="waitingForAnswer"
                class="question-text"
                outlined
              ></v-textarea>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                class="button"
                color="blue"
                justify="end"
                :disabled="path == null || question == null || waitingForAnswer"
                @click="getAnswerFromLocalFiles"
              >
                Submit
              </v-btn>
            </v-card-actions>
            <!-- <v-btn @click="getDropBoxFiles">Dropbox</v-btn> -->
          </v-card>
        </v-container>
      </v-col>
      <v-col cols="12" lg="6" xl="6" md="12" sm="12">
        <v-container class="answer-container">
          <transition name="fade" v-if="waitingForAnswer">
            <v-overlay class="answer-container-absolute" :opacity="0.2">
              <circle-loader color="#22f" :size="100" />
              <!-- <v-progress-circular
              :size="50"
              :width="5"
              color="lightgrey"
              indeterminate
            ></v-progress-circular> -->
            </v-overlay>
          </transition>
          <v-card class="card-answer" elevation="5">
            <v-card-title>Answer</v-card-title>
            <v-row class="align-center" justify="center">
              <v-col cols="4" offset="4">
                <v-select
                  :items="voiceList"
                  key="name"
                  label="Voice"
                  item-text="name"
                  item-value="voiceURI"
                  v-model="answerVoiceName"
                  density="compact"
                />
              </v-col>
              <v-col cols="auto">
                <scale-loader
                  class="d-flex justify-center loading-voices"
                  color="#dd3333"
                  size="15"
                  margin="2px"
                />
                <v-btn
                  :disabled="!this.answer"
                  text
                  icon
                  @click="toggleSpeakAnswer"
                  :class="{ 'pulse': isTalkingAnswer }"
                >
                  <v-img
                    :src="speakOutAnswer ? iconSpeakAnswer : iconNoSpeakAnswer"
                    width="50px"
                  ></v-img>
                </v-btn>
              </v-col>
            </v-row>
            <v-card-text>
              <v-textarea
                class="answer-text"
                v-model="answer"
                outlined
                readonly
                scrollable
              ></v-textarea>
            </v-card-text>
            <v-card-subtitle v-if="answer_filenames"
              ><h3>Answer was given from:</h3>
              {{ answer_filenames }}</v-card-subtitle
            >
          </v-card>
        </v-container>
      </v-col>
    </v-row>

    <v-row class="footer px-3 mx-auto">
      <v-col cols="12" lg="5" xl="5" md="12" sm="12">
        <v-card
          elevation="5"
          class="card"
          :color="files.length == 0 || serverIsDown ? '#ff3030' : '#309930'"
        >
          <v-card-title class="card-title">Files</v-card-title>
          <v-data-table
            class="virtual-scroll-table"
            v-scroll:#virtual-scroll-table="onScroll"
            :headers="headers"
            :items="files"
            disable-pagination
            :hide-default-footer="true"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <!-- <v-icon small class="mr-2" @click="editFile(item.id)">mdi-pencil</v-icon> -->
              <v-icon small @click="deleteFile(item.id)">mdi-delete</v-icon>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              class="button"
              :disabled="waitingForAnswer || files.length == 0"
              small
              color="error"
              @click="deleteAllFiles"
            >
              Remove All
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col
        class="upload-file-container"
        cols="12"
        lg="4"
        offset-lg="3"
        xl="4"
        offset-xl="3"
        md="12"
        sm="12"
      >
        <v-card elevation="5" class="card" :disabled="uploading">
          <v-overlay
            v-if="uploading"
            class="d-flex flex-column justify-center align-center class-absolute"
            :value="uploading"
            :opacity="1"
          >
            <v-progress-circular
              :size="100"
              :width="10"
              color="lightgrey"
              indeterminate
            ></v-progress-circular>
          </v-overlay>
          <v-card-title class="card-title">Upload File</v-card-title>
          <v-file-input
            class="file-input"
            counter
            show-size
            small-chips
            truncate-length="30"
            v-model="selectedFile"
            accept=".pdf, .docx"
          ></v-file-input>
          <v-card-actions class="upload-button justify-end">
            <v-btn
              class="button"
              color="green"
              :disabled="uploading || !selectedFile"
              @click="uploadLocalFile"
              >Upload</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
// import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
// import { ScaleLoader, CircleLoader } from "@saeris/vue-spinners";

export default {
  name: "Home",
  components: {
    HelloWorld,
    PulseLoader,
    // ScaleLoader,
    // CircleLoader,
  },
  data: () => ({
    folders: [],
    files: [],
    headers: [
      { text: "Name", align: "start", sortable: false, value: "name" },
      // { text: "Path", value: "path_display", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
      // { text: "Client Modified", value: "client_modified", sortable: false },
      // { text: "Server Modified", value: "server_modified", sortable: false },
    ],

    apiUrl: "http://aied.ddns.net:5000/api",
    // apiUrl: "http://localhost:5000/api",
    dropbox_path_link: null,
    previews: [],
    path: "",
    selectedFile: null,
    local_path: null,
    local_file: null,
    dropbox_file_path: "AI_in_Education",
    question: null,
    answer: null,
    waitingForAnswer: false,
    filesTimer: null,
    uploading: false,
    showMessage: false,

    tokenPrediction: false,
    tokenPredictors: [
      { name: "GPTTreeIndex" },
      { name: "GPTKeywordTableIndex" },
      { name: "GPTListIndex" },
    ],
    tokenPredictor: null,
    isVoicesLoading: true,
    isTalking: false,
    isTalkingQuestion: false,
    isTalkingAnswer: false,
    synth: window.speechSynthesis,
    serverIsDown: false,
    voiceList: [],
    answerVoice: null,
    answerVoiceName: null,
    answer_filenames: null,
    questionVoice: null,
    questionVoiceName: null,
    speechSynthesisUtterance: new window.SpeechSynthesisUtterance(),
    speakOutAnswer: true,
    iconSpeakAnswer: require("@/assets/speak.png"),
    iconNoSpeakAnswer: require("@/assets/no-speak.png"),
    speakOutQuestion: false,
    iconSpeakQuestion: require("@/assets/speak.png"),
    iconNoSpeaking: require("@/assets/nospeaking.png"),
    iconSpeaking: [
      require("@/assets/speaking1.png"),
      require("@/assets/speaking2.png"),
      require("@/assets/speaking3.png"),
    ],
    iconNoSpeakQuestion: require("@/assets/no-speak.png"),
  }),
  async created() {
    // this.path = "AI_in_Education";
    // this.getDropBoxFiles(this.path);
    this.loadVoices();
  },
  mounted() {
    this.path = "data";
    this.stopSpeech();
    this.getLocalFiles(this.path);
    this.filesTimer = setInterval(() => {
      this.getLocalFiles(this.path);
    }, 5000);

    this.synth.onvoiceschanged = () => {
      this.loadVoices();
    };
  },
  beforeDestroy() {
    clearInterval(this.filesTimer);
    this.stopSpeech();
  },
  computed: {},

  watch: {},
  methods: {
    loadVoices() {
      // wait for voices to load
      // I can't get FF to work without calling this first
      // Chrome works on the onvoiceschanged function
      this.voiceList = [];
      const voices = this.synth.getVoices();
      if (voices.length > 0) {
        // console.log('voices: ', voices);
        for (let i = 0; i < voices.length; i++) {
          let voice = {
            index: 0,
            default: false,
            lang: "",
            localService: true,
            name: "",
            voiceURI: "",
          };
          voice.index = i;
          voice.default = voices[i].default;
          voice.lang = voices[i].lang;
          voice.name = voices[i].name;
          voice.voiceURI = voices[i].voiceURI;
          this.voiceList.push(voices[i]);
        }
      }

      if (this.voiceList.length) {
        this.isVoicesLoading = false;
      }

      // give a bit of delay to show loading screen
      // just for the sake of it, I suppose. Not the best reason
      setTimeout(() => {
        this.isVoicesLoading = false;
        this.answerVoiceName = this.voiceList[0].name;
        this.questionVoiceName = this.voiceList[0].name;
      }, 800);

      this.listenForSpeechEvents();
    },

    extractUniqueFilenames(jsonString) {
      console.log("json text: ", jsonString);
      // Parse the JSON string into a JavaScript object
      var data = jsonString;

      // Create an empty set to store unique file names
      var filenamesSet = new Set();

      // Loop through each entry in the data object
      for (var key in data) {
        if (data.hasOwnProperty(key)) {
          // Get the file name and strip out the path
          var filename = data[key].file_name.split("\\").pop();
          // Add the filename to the set (automatically removes duplicates)
          filenamesSet.add(filename);
        }
      }

      // Convert the set back to an array
      var uniqueFilenames = Array.from(filenamesSet).join(", ");

      return uniqueFilenames;
    },

    async getLocalFiles(path) {
      try {
        const response = await fetch(
          `${this.apiUrl}/local-files?files-path=${path}`,
          {
            method: "GET",
          }
        );
        this.serverIsDown = false;
        const data = await response.json();
        this.files = data.files;
        // console.log("files: ", this.files);
      } catch (error) {
        this.files = [];
        if (error.message === "Failed to fetch") {
          console.error("Server is down");
          this.serverIsDown = true;
          // Handle the error as needed
        } else {
          console.error("An unknown error occurred:", error);
          // Handle other errors as needed
        }
      }
    },

    async getAnswerFromLocalFiles() {
      clearInterval(this.filesTimer);
      this.waitingForAnswer = true;
      if (this.path == null || this.question == null) {
        return;
      }
      this.answer = "";
      this.answer_filenames = "";
      this.stopSpeech();
      let api_v2 = false;
      try {
        const response = await fetch(`${this.apiUrl}/local-files/data/`, {
          // const response = await fetch(`${this.apiUrl}/v2/local-files/data/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            files_path: this.path,
            question: this.question,
          }),
        });
        const data = await response.json();
        console.log("data: ", data);
        if (data.status == 200) {
          if (api_v2) {
            this.answer = data.answer;
          } else {
            this.answer = data.answer.response;
            console.log("json extra info: ", data.answer.extra_info);
            this.answer_filenames = this.extractUniqueFilenames(
              data.answer.extra_info
            );
            console.log("filenames: ", this.answer_filenames);
          }
        } else if (data.status == 500) {
          console.error("Internal Server Error: ", data.exception);
        }
      } catch (error) {
        console.log(error);
      }
      this.waitingForAnswer = false;
      this.filesTimer = setInterval(() => {
        this.getLocalFiles(this.path);
      }, 5000);
    },

    async getDropBoxFiles() {
      try {
        const response = await fetch(
          `${this.apiUrl}/dropbox?path=${this.path}`,
          {
            method: "GET",
          }
        );
        const data = await response.json();
        this.files = JSON.parse(JSON.stringify(data.files));
        this.dropbox_path_link = JSON.parse(JSON.stringify(data.path_link));
        console.log("files: ", this.files);
        console.log("dropbox path link: ", this.dropbox_path_link);
      } catch (error) {
        console.log(error);
      }
    },

    async uploadToDropBox() {
      if (this.selectedFile.length == 0) {
        return;
      }
      const reader = new FileReader();
      console.log("selected file: ", this.selectedFile);
      this.local_file = this.selectedFile;
      this.local_path = reader.readAsDataURL(this.selectedFile);
      console.log("name: ", this.local_file);
      console.log("path: ", this.local_path);
      console.log("previews: ", this.previews);
      try {
        const response = await fetch(`${this.apiUrl}/dropbox/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            local_path: this.local_path,
            local_file: this.local_file,
            dropbox_file_path: this.dropbox_file_path,
          }),
        });
        const data = await response.json();
        console.log("data: ", data);
      } catch (error) {
        console.log(error);
      }
    },
    onSelectFile() {
      console.log("in on select file");
      this.previews = [];
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        (e) => (this.previews[0] = e.target.result)
      );
      reader.addEventListener("error", (e) => (this.previews[0] = "Error"));
      reader.readAsText(this.selectedFile);
    },

    async uploadLocalFile() {
      var formdata = new FormData();
      formdata.append("file", this.file);
      const h = new Headers();
      var requestOptions = {
        method: "POST",
        headers: h,
        body: formdata,
      };
      this.uploading = true;
      try {
        const response = await fetch(
          `${this.apiUrl}/device-logger-events-upload`,
          requestOptions
        );
        if (response.status == 401) {
          this.$store.dispatch("auth/logout").then(
            () => {
              this.$router.push("/login");
            },
            (error) => {
              this.loading = false;
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            }
          );
        }
        this.showMessage = true;
        const data = await response.json();
        this.message = data.message;
        this.uploading = false;
        if (data.success) {
          this.messageType = "success";
        } else {
          this.messageType = "error";
        }
        window.setTimeout(() => {
          this.showMessage = false;
        }, 5000);
      } catch (error) {
        console.log("error", error);
        this.uploading = false;
        this.message = "Error: " + error;
        this.uploading = false;
        this.messageType = "error";
        window.setTimeout(() => {
          this.showMessage = false;
        }, 5000);
      }
    },
    deleteFile() {},
    deleteAllFiles() {},

    onScroll(e) {
      // debounce if scrolling fast
      this.timeout && clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        const { scrollTop } = e.target;
        const rows = Math.ceil(scrollTop / this.rowHeight);

        this.start =
          rows + this.perPage > this.desserts.length
            ? this.desserts.length - this.perPage
            : rows;

        this.$nextTick(() => {
          e.target.scrollTop = scrollTop;
        });
      }, 20);
    },

    async getAnswer() {
      console.log("question: ", this.question);
      this.answer = null;
      try {
        const response = await fetch("${this.apiUrl}/google-drive/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            files_path: this.dropbox_path_link,
            question: this.question,
          }),
        });
        const data = await response.json();
        console.log("data: ", data);
        if (data.status == 200) {
          this.answer = data.answer.response;
        } else if (data.status == 500) {
          console.error("Internal Server Error: ", data.exception);
        }
      } catch (error) {
        console.log(error);
      }
    },

    listenForSpeechEvents() {
      this.speechSynthesisUtterance.onstart = () => {
        console.log("isTalking true");
        this.isTalking = true;
      };

      this.speechSynthesisUtterance.onend = () => {
        console.log("isTalking false");
        this.isTalkingQuestion = false;
        this.isTalkingAnswer = false;
        this.isTalking = false;
      };
    },

    toggleSpeakQuestion() {
      this.speakOutQuestion = !this.speakOutQuestion;
      if (!this.speakOutQuestion) {
        this.stopSpeech();
      } else {
        this.questionVoice = this.voiceList.find(
          (voice) => voice.name == this.questionVoiceName
        );
        this.speakOutAnswer = false;
        this.stopSpeech();
        this.speak(this.question);
        this.isTalkingQuestion = true;
      }
    },

    toggleSpeakAnswer() {
      this.speakOutAnswer = !this.speakOutAnswer;
      if (!this.speakOutAnswer) {
        this.stopSpeech();
      } else if (this.speakOutAnswer) {
        this.answerVoice = this.voiceList.find(
          (voice) => voice.name == this.answerVoiceName
        );
        this.speakOutQuestion = false;
        this.stopSpeech();
        this.speak(this.answer);
        this.isTalkingAnswer = true;
      }
    },

    initiateSpeakingIcon() {
      let i = 0;
      while (this.isTalking) {
        console.log("in while loop: ", i);
        this.speakingIconTimer = setInterval(() => {
          this.iconSpeakQuestion = this.iconSpeaking[i];
          console.log("i: ", i);
        }, 500);
        if(i == 2) {
          i = 0;
        } else {
          i++;
        }
      }
    },

    speak(text) {
      if (
        ((!this.speakOutAnswer || !this.answer) && !this.speakOutQuestion) ||
        !this.question
      ) {
        return;
      }
      if (this.speakOutAnswer) {
        console.log("selected answer voice: ", this.answerVoice);
        this.speechSynthesisUtterance.voice = this.answerVoice;
      }
      if (this.speakOutQuestion) {
        console.log("selected question voice: ", this.questionVoice);
        this.speechSynthesisUtterance.voice = this.questionVoice;
      }

      console.log("in speak: ", text);
      this.speechSynthesisUtterance.text = text;

      this.synth.speak(this.speechSynthesisUtterance);
      this.initiateSpeakingIcon();
    },
    stopSpeech() {
      window.speechSynthesis.cancel();
    },
  },
};
</script>

<style scoped>
.alert-container {
  width: 100%;
  position: absolute;
  z-index: 1;
}
.alert {
  width: 100%;
}
.voice {
  border-radius: 18px;
  width: 40%;
  /* margin: 20px; */
  /* padding: 10px; */
}

.pulse {
	background: green;
	border-radius: 50%;
	margin: 10px;
	height: 20px;
	width: 20px;

	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse .3s infinite;
}
@keyframes pulse {
	0% {
		transform: scale(0.8);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.8);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.qa-container {
  padding: 10px;
}
.server-is-down {
  position: absolute;
  font-size: 3.5rem;
  font-weight: bold;
  z-index: 100;
}
.answer-container {
  margin: 0 auto;
  max-width: 4096px;
  /* margin-top: 10px; */
  position: relative;
}
.answer-container-absolute {
  margin: 10px;
  /* height: 180px;
  width: 280px; */
  position: absolute;
  border-radius: 12px;
  z-index: 100;
}
.question-container {
  margin: 0 auto;
  max-width: 4096px;
  /* margin-top: 10px; */
  position: relative;
}
.loading-voices-container {
  padding: 0;
  margin: 0;
  position: relative;
}
.loading-voices {
  margin: 0px;
  position: absolute;
  border-radius: 7px;
}
.card-answer {
  border-radius: 18px;
  /* height: 700px; */
  /* background-color: #eee; */
}
.card-question {
  border-radius: 18px;
  /* background-color: #eee; */
}
.graph-absolute {
  margin: 0px;
  position: absolute;
  border-radius: 7px;
}
.card-details {
  margin-bottom: 10px;
}
.alert {
  width: 100%;
}
.upload-file-container {
  display: block;
  align-self: end;
  height: 100%;
}
.file-input {
  padding: 0.8em;
}
.card {
  border-radius: 18px;
  width: 90%;
  margin: 20px;
  margin-bottom: 20px;
  /* background-color: #eee; */
}
.card-title {
  justify-content: center;
  font-size: 1.3em;
}
.card-title-valid {
  justify-content: center;
  color: green;
}
.card-title-invalid {
  justify-content: center;
  color: red;
}
.answer-text {
  /* background-color: #fff; */
}
.question-text {
  /* background-color: #fff; */
}
.upload-button {
  border-radius: 18px;
  justify-self: end;
  padding: 0.8em;
  margin-top: 10px;
}
.class-absolute {
  height: 100%;
  width: 100%;
  position: absolute;
}
.footer {
  align-self: end;
  width: 100%;
  /* position: fixed; */
  bottom: 0;
}
.virtual-scroll-table {
  height: 200px;
  max-height: 200px;
  overflow: auto;
}
.v-spinner {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity ease 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.btn-success {
  background: #43c6ac;
  border-color: #43c6ac;
  cursor: pointer;
}

.button {
  color: white;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 10px;
}
</style>