<template>
  <v-app>
    <v-app-bar app color="primary" dark>
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
      <!-- <LogoLTU /> -->
      <v-row class="text-center">
        <v-col cols="12">
          <h2>Conversational AI for Education</h2>
        </v-col>
      </v-row>

      <!-- <v-spacer></v-spacer> -->

      <!-- <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-main class="main-body">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// import LogoLTU from './assets/logo.png';

export default {
  name: "App",
  // components: { LogoLTU },
  data: () => ({
    //
  }),
};
</script>

<style>
.main-body {
  background-color: #79c;
}
.title-center {
  align-items: center;
}
</style>